import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const PencilOutline = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="6"
        height="20"
        viewBox="0 0 6 20"
        fill="none"
        transform="rotate(45)"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="path-1-inside-1_1301_76846" fill="white">
          <path d="M0 0.5C0 0.223858 0.223858 0 0.5 0H5.5C5.77614 0 6 0.223858 6 0.5V4.75V16.625L3 20L0 16.625V4.75V0.5Z" />
        </mask>
        <path
          d="M0 5.5H6V4H0V5.5ZM6 16.625L7.12111 17.6215L7.5 17.1953V16.625H6ZM0 16.625H-1.5V17.1953L-1.12111 17.6215L0 16.625ZM3 20L1.87889 20.9965L3 22.2578L4.12111 20.9965L3 20ZM0.5 1.5H5.5V-1.5H0.5V1.5ZM4.87889 15.6285L1.87889 19.0035L4.12111 20.9965L7.12111 17.6215L4.87889 15.6285ZM4.12111 19.0035L1.12111 15.6285L-1.12111 17.6215L1.87889 20.9965L4.12111 19.0035ZM1.5 16.625V4.75H-1.5V16.625H1.5ZM1.5 4.75V0.5H-1.5V4.75H1.5ZM4.5 0.5V4.75H7.5V0.5H4.5ZM4.5 4.75V16.625H7.5V4.75H4.5ZM5.5 1.5C4.94771 1.5 4.5 1.05228 4.5 0.5H7.5C7.5 -0.604569 6.60457 -1.5 5.5 -1.5V1.5ZM0.5 -1.5C-0.60457 -1.5 -1.5 -0.604569 -1.5 0.5H1.5C1.5 1.05228 1.05228 1.5 0.5 1.5V-1.5Z"
          fill="white"
          mask="url(#path-1-inside-1_1301_76846)"
        />
      </svg>
    </SvgIcon>
  )
}

export default PencilOutline
