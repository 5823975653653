import React, { useState, useRef, useEffect } from "react"
import { Box, Chip } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

interface Props {
  zones: Array<{ name: string }>
}

const ZoneChipsDisplay = ({ zones }: Props) => {
  const [expanded, setExpanded] = useState(false)
  const [visibleCount, setVisibleCount] = useState(zones.length)
  const containerRef = useRef<HTMLDivElement>(null)
  const firstRowRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!containerRef.current || !firstRowRef.current) return

    const container = containerRef.current
    const chipWidth = 70 // Approximate width of each chip including margin
    const containerWidth = container.offsetWidth
    const maxChipsInRow = Math.floor(containerWidth / chipWidth)

    if (!expanded && zones.length > maxChipsInRow) {
      setVisibleCount(maxChipsInRow - 1) // -1 to make room for the "+X" chip
    } else {
      setVisibleCount(zones.length)
    }
  }, [zones.length, expanded])

  const remainingCount = zones.length - visibleCount

  return (
    <Box sx={{ width: "100%" }} ref={containerRef}>
      <Box
        ref={firstRowRef}
        sx={{
          display: "flex",
          flexWrap: expanded ? "wrap" : "nowrap",
          gap: "4px",
          alignItems: "center"
        }}
      >
        {zones
          .slice(0, expanded ? zones.length : visibleCount)
          .map((zone, index) => (
            <Chip
              key={index}
              size="small"
              label={zone.name}
              sx={{
                border: "1px solid",
                borderColor: "primary.contrastText",
                backgroundColor: "secondary.main",
                fontWeight: "600",
                fontSize: "12px",
                height: "20px"
              }}
            />
          ))}
        {!expanded && remainingCount > 0 && (
          <Chip
            size="small"
            label={`+${remainingCount}`}
            onClick={(e) => {
              e.stopPropagation()
              setExpanded(true)
            }}
            sx={{
              backgroundColor: "hint.light",
              color: "primary.light",
              fontWeight: "600",
              fontSize: "12px",
              height: "20px",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "secondary.light"
              }
            }}
          />
        )}
        {expanded && (
          <Chip
            size="small"
            label={<CloseIcon sx={{ fontSize: 12, display: "flex" }} />}
            onClick={(e) => {
              e.stopPropagation()
              setExpanded(false)
            }}
            sx={{
              backgroundColor: "hint.light",
              color: "primary.light",
              height: "20px",
              cursor: "pointer",
              "& .MuiChip-label": {
                padding: "0 5px"
              }
            }}
          />
        )}
      </Box>
    </Box>
  )
}

export default ZoneChipsDisplay
