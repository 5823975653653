import React from "react"
import dayjs from "dayjs"
import StatusBadge from "../../StatusBadge"
import NoRowOverlay from "../NoRowOverlay"
import * as amplitude from "@amplitude/analytics-browser"
import { Campaign } from "../../../models/campaign"
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridApiCommon
} from "@mui/x-data-grid"
import { Box, Typography, CircularProgress } from "@mui/material"
import { makeStyles } from "@material-ui/styles"
import { useNavigate } from "react-router-dom"
import { useQuery, gql } from "urql"
import ZoneChipsDisplay from "../ZoneChipsDisplay"

const GET_CAMPAIGN_VIDEO_URL = gql`
  query getVideoUrl($key: String!) {
    s3Url(key: $key)
  }
`

interface Props {
  showLoadingIndicator: boolean
  campaigns: Campaign[]
  searchInput: string
  handleClearInput: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apiRef: React.MutableRefObject<GridApiCommon<any, any>>
  previewUrls?: Record<number, string>
}

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
      outline: "none"
    },
    "& .MuiDataGrid-row:hover": {
      cursor: "pointer"
    }
  }
})

const PromotionListTable = (props: Props) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const [isScrolled, setIsScrolled] = React.useState(false)

  React.useEffect(() => {
    if (props.apiRef?.current) {
      const unsubscribe = props.apiRef?.current.subscribeEvent(
        "scrollPositionChange",
        ({ top }) => {
          setIsScrolled(top > 0)
        }
      )

      return () => {
        unsubscribe()
      }
    }
  }, [props.apiRef])

  const headerStyles = {
    boxShadow: isScrolled ? "0px 4px 6px rgba(0, 0, 0, 0.15)" : "none",
    minHeight: "36px !important",
    maxHeight: "36px !important",
    lineHeight: "36px !important"
  }

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 127,
      flex: 2.5,
      sortable: false,
      renderCell: (params) => {
        const assetId = params.row.videoId

        const [{ data: campaignVideo, fetching, error }] = useQuery({
          query: GET_CAMPAIGN_VIDEO_URL,
          pause: !assetId,
          variables: {
            key: `videos/${assetId}/${assetId}.timg1.png`
          }
        })

        const previewUrl = campaignVideo?.s3Url

        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="24px"
          >
            <Box
              sx={{
                width: "48px",
                height: "48px",
                borderRadius: "4px",
                overflow: "hidden",
                bgcolor: "asset.light",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              {assetId &&
                !error &&
                (fetching ? (
                  <CircularProgress size={24} />
                ) : (
                  <img
                    src={previewUrl}
                    alt="No Preview"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "4px"
                    }}
                  />
                ))}
            </Box>
            <Typography variant="body2" noWrap title={params.row.name}>
              {params.row.name}
            </Typography>
          </Box>
        )
      }
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      sortable: false,
      renderCell: (params) => (
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
          }}
          title={params.row.status}
        >
          <StatusBadge
            sx={{ textTransform: "capitalize" }}
            status={params.row.status}
          />
        </Box>
      )
    },
    {
      field: "storeArea",
      headerName: "Zone",
      width: 127,
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            {params.row.zonePlacements?.length > 0 && (
              <ZoneChipsDisplay zones={params.row.zonePlacements} />
            )}
          </Box>
        )
      }
    },
    {
      field: "startDate",
      renderHeader: () => {
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="2px"
            sx={{
              padding: "0px",
              margin: "0px"
            }}
          >
            <Typography variant="body2">Start Date</Typography>
          </Box>
        )
      },
      width: 115,
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography
            variant="body2"
            noWrap
            title={dayjs(params.row.startDate).format("MMM DD, YYYY")}
          >
            {dayjs(params.row.startDate).format("MMM DD, YYYY")}
          </Typography>
        )
      }
    },
    {
      field: "endDate",
      renderHeader: () => {
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="2px"
            sx={{
              padding: "0px",
              margin: "0px"
            }}
          >
            <Typography variant="body2">End Date</Typography>
          </Box>
        )
      },
      width: 115,
      sortable: false,
      renderCell: (params) => {
        const endDate = dayjs(params.row.endDate)
        const formattedDate = endDate.isSame(dayjs("2099-09-09"), "day")
          ? "-"
          : endDate.format("MMM DD, YYYY")

        return (
          <Typography variant="body2" noWrap title={formattedDate}>
            {formattedDate}
          </Typography>
        )
      }
    }
  ]

  const handleRowClick = (param: GridRowParams) => {
    const id = param.row.id as string
    amplitude.track("viewCampaign", {
      campaignName: param.row.name,
      campaignStoreCount: param.row.storeCount,
      campaignStartDate: param.row.startDate,
      campaignEndDate: param.row.endDate
    })
    navigate(`/promotions/${id}`)
  }

  return (
    <DataGrid
      apiRef={props.apiRef}
      hideFooter
      classes={{ root: classes.root }}
      rowHeight={80}
      rows={props.campaigns}
      columns={columns}
      onRowClick={handleRowClick}
      loading={props.showLoadingIndicator}
      disableColumnFilter
      disableColumnMenu
      sx={{
        marginBottom: "18px",
        height: "70vh",
        "& .MuiDataGrid-columnHeaders": headerStyles,
        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within":
          {
            outline: "none"
          },
        "& .MuiDataGrid-columnSeparator": {
          display: "none"
        }
      }}
      slots={{
        noRowsOverlay: () => (
          <NoRowOverlay
            searchInput={props.searchInput}
            handleClearInput={props.handleClearInput}
          />
        )
      }}
      disableColumnSelector
      disableRowSelectionOnClick
    />
  )
}

export default PromotionListTable
