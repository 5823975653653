import React from "react"
import { useWatch } from "react-hook-form"
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@mui/material"
import { StoreTargetMapping, PropertyItem } from "../../../models/storetarget"

const ReviewStep = () => {
  const propertyName = useWatch({
    name: "propertyName"
  })

  const propertyItems: PropertyItem[] = useWatch({
    name: "propertyItems",
    defaultValue: []
  })

  const propertyType = useWatch({
    name: "propertyType"
  })

  const StoreTargetMappings: StoreTargetMapping[] = useWatch({
    name: "StoreTargetMappings"
  })

  const renderTable = (
    filteredData: StoreTargetMapping[],
    title: string,
    key: string | number
  ) => {
    const tableHeadStyle = {
      width: "25%",
      textAlign: "left",
      padding: "0 0 0 16px",
      height: "36px"
    }
    return (
      <Box
        key={key}
        sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
      >
        <Typography>{title}</Typography>
        <Box
          sx={{
            border: "1px solid",
            borderColor: "secondary.light",
            borderRadius: "8px"
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={tableHeadStyle}>Store</TableCell>
                <TableCell sx={tableHeadStyle}>Banner</TableCell>
                <TableCell sx={tableHeadStyle}>Location</TableCell>
                <TableCell sx={tableHeadStyle}>{propertyName}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((row, index) => {
                const cellStyle = {
                  padding: "0 0 0 16px",
                  borderBottom:
                    index === filteredData.length - 1 ? "none" : null
                }
                return (
                  <TableRow key={row.storesId} sx={{ height: "36px" }}>
                    <TableCell sx={cellStyle}>{row.store}</TableCell>
                    <TableCell sx={cellStyle}>{row.bannerName}</TableCell>
                    <TableCell sx={cellStyle}>{row.location}</TableCell>
                    <TableCell sx={cellStyle}>{row.propertyValue}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "40px",
        overflowY: "auto",
        gap: "24px"
      }}
    >
      <Typography variant="body2" sx={{ fontSize: "20px" }}>
        Review Store Assignments
      </Typography>

      {propertyItems.map((item, index) => {
        const filteredData = StoreTargetMappings.filter(
          (mapping) => mapping.propertyValue === item.value
        )
        return renderTable(filteredData, item.value, index)
      })}

      {propertyType === "multi-select" &&
        renderTable(
          StoreTargetMappings.filter(
            (mapping) => mapping.propertyValue === "Not Applicable"
          ),
          "Not Applicable",
          "not-applicable"
        )}
    </Box>
  )
}

export default ReviewStep
