import React from "react"
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Typography
} from "@mui/material"
import { NewCampaignStore } from "../../models/campaign"

interface PropertyStoreSelectorTableProps {
  missingStores: NewCampaignStore[]
  selectedStoreId: number | null
  onStoreSelect: (storeId: number) => void
}

const PropertyStoreSelectorTable = ({
  missingStores,
  selectedStoreId,
  onStoreSelect
}: PropertyStoreSelectorTableProps) => {
  return (
    <Box
      sx={{
        overflowY: "auto"
      }}
    >
      <Table
        stickyHeader
        sx={{
          "& .MuiTableCell-root": {
            padding: "0 8px",
            width: "33.33%",
            height: "36px"
          },
          "& .MuiTableCell-head:first-of-type": {
            paddingLeft: "16px"
          }
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: "fileInput.dark" }}>Store</TableCell>
            <TableCell sx={{ color: "fileInput.dark" }}>Banner</TableCell>
            <TableCell sx={{ color: "fileInput.dark" }}>City, State</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {missingStores.map((store) => (
            <TableRow
              key={store.id}
              onClick={() => onStoreSelect(store.id)}
              sx={{
                backgroundColor:
                  selectedStoreId === store.id ? "hint.light" : "inherit"
              }}
            >
              <TableCell>
                <Box display="flex" alignItems="center" paddingLeft="5px">
                  <Checkbox
                    checked={selectedStoreId === store.id}
                    disableRipple
                    readOnly
                    size="small"
                    sx={{
                      color: "secondary.dark",
                      padding: 0,
                      "&.Mui-checked": {
                        color: "primary.light"
                      }
                    }}
                  />
                  <Typography variant="body2" paddingLeft="8px">
                    {store.name}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {store.banner?.name || "N/A"}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {`${store.address?.city || ""}, ${
                    store.address?.state || ""
                  }`}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

export default PropertyStoreSelectorTable
