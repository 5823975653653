/* eslint-disable indent */
import React from "react"
import {
  Box,
  CircularProgress,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Typography
} from "@mui/material"
import NoRowOverlay from "../promotions/NoRowOverlay"
import { NewCampaignStore } from "../../models/campaign"
import ArrowDown from "../icons/ArrowDown"
import ArrowUp from "../icons/ArrowUp"

interface StoreSelectorTableProps {
  storeSearchTerm: string | undefined
  isLoadingStores: boolean
  isCampaignEnded?: boolean
  sortTargetsByColumn: string
  sortTargetsDirection: "asc" | "desc"
  onSortChange: (columnName: string) => void
  isTargetSelected: (targetId: number) => boolean
  onTargetChange: (targetId: number) => void
  sortedTargets: NewCampaignStore[]
  onSearchClear: () => void
  onSelectAllTargets: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
  isAllTargetsSelected: boolean
  isReadOnly?: boolean
}

const StoreSelectorTable = ({
  storeSearchTerm,
  isLoadingStores,
  isCampaignEnded = false,
  sortTargetsByColumn,
  sortTargetsDirection,
  onSortChange,
  isTargetSelected,
  onTargetChange,
  sortedTargets,
  onSearchClear,
  onSelectAllTargets,
  isAllTargetsSelected,
  isReadOnly
}: StoreSelectorTableProps) => {
  const renderSortIcon = (columnName: string) => {
    if (sortTargetsByColumn !== columnName) return null
    return sortTargetsDirection === "asc" ? (
      <ArrowUp sx={{ height: "14px", width: "14px" }} />
    ) : (
      <ArrowDown sx={{ height: "14px", width: "14px" }} />
    )
  }

  const renderHeaderCell = (columnName: string) => (
    <Box
      display="flex"
      alignItems="center"
      gap="2px"
      sx={{
        cursor: isCampaignEnded ? "default" : "pointer",
        opacity: isCampaignEnded ? 0.5 : 1
      }}
      onClick={isCampaignEnded ? undefined : () => onSortChange(columnName)}
    >
      <Typography variant="body2" sx={{ color: "fileInput.dark" }}>
        {columnName}
      </Typography>
      {renderSortIcon(columnName)}
    </Box>
  )

  return (
    <Box
      sx={{
        display: "flex",
        overflow: "auto",
        height: "auto",
        maxHeight: "406px",
        justifyContent: "center"
      }}
    >
      <Table
        stickyHeader
        sx={{
          "& .MuiTableCell-root": {
            padding: "0 8px",
            width: "33.33%"
          }
        }}
      >
        <TableHead>
          <TableRow sx={{ opacity: isCampaignEnded ? 0.5 : 1, height: "36px" }}>
            <TableCell>
              <Box
                display="flex"
                alignItems="center"
                paddingLeft={isReadOnly ? "10px" : "0"}
              >
                {!isReadOnly && (
                  <Checkbox
                    disabled={isCampaignEnded || isReadOnly}
                    checked={isAllTargetsSelected}
                    onChange={onSelectAllTargets}
                    sx={{
                      color: "secondary.dark",
                      height: "36px",
                      width: "36px",
                      borderRadius: "50%",
                      "&.Mui-checked": {
                        color: "primary.light"
                      }
                    }}
                  />
                )}

                {renderHeaderCell("Store")}
              </Box>
            </TableCell>
            <TableCell>{renderHeaderCell("Banner")}</TableCell>
            <TableCell>{renderHeaderCell("City, State")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingStores ? (
            <TableRow>
              <TableCell colSpan={3} sx={{ border: "none" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px"
                  }}
                >
                  <CircularProgress />
                </Box>
              </TableCell>
            </TableRow>
          ) : storeSearchTerm !== undefined && sortedTargets.length === 0 ? (
            <TableRow sx={{ height: "369px" }}>
              <TableCell
                colSpan={3}
                sx={{
                  border: "none",
                  height: "100%"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%"
                  }}
                >
                  <NoRowOverlay
                    searchInput={storeSearchTerm}
                    handleClearInput={onSearchClear}
                  />
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            sortedTargets.map((target) => {
              if (isReadOnly && !isTargetSelected(target.id)) {
                return null
              }
              return (
                <TableRow
                  key={target.id}
                  sx={{
                    opacity: isCampaignEnded ? 0.5 : 1,
                    backgroundColor:
                      isTargetSelected(target.id) && !isReadOnly
                        ? "hint.light"
                        : "inherit",
                    height: "36px"
                  }}
                >
                  <TableCell>
                    <Box
                      display="flex"
                      alignItems="center"
                      paddingLeft={isReadOnly ? "10px" : "0"}
                    >
                      {!isReadOnly && (
                        <Checkbox
                          checked={isTargetSelected(target.id)}
                          onChange={() => onTargetChange(target.id)}
                          disabled={isCampaignEnded}
                          sx={{
                            color: "secondary.dark",
                            height: "36px",
                            width: "36px",
                            borderRadius: "50%",
                            "&.Mui-checked": {
                              color: "primary.light"
                            }
                          }}
                        />
                      )}
                      <Typography variant="body2">{target.name}</Typography>
                    </Box>
                  </TableCell>

                  <TableCell>{target.banner?.name}</TableCell>
                  <TableCell>{`${target.address?.city}, ${target.address?.state}`}</TableCell>
                </TableRow>
              )
            })
          )}
        </TableBody>
      </Table>
    </Box>
  )
}

export default StoreSelectorTable
