import React from "react"
import { Box, Checkbox, Typography, TableRow, TableCell } from "@mui/material"
import { StoreTargetMapping } from "../../../../models/storetarget"
import { useWatch } from "react-hook-form"

interface Props {
  target: StoreTargetMapping
  selected: boolean
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
}

const StoreMappingRow = ({ target, selected, onChange }: Props) => {
  const selectedButton = useWatch({
    name: "selectedButton"
  })

  return (
    <TableRow
      sx={{
        backgroundColor: selected ? "hint.light" : "secondary.main"
      }}
    >
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center", paddingLeft: "8px" }}>
          <Checkbox
            checked={selected}
            size="small"
            sx={{
              color: "secondary.dark",
              fontSize: "14px",
              padding: 0,
              "&.Mui-checked": {
                color: "primary.light"
              }
            }}
            onChange={onChange}
          />
          <Typography
            variant="body2"
            sx={{
              paddingLeft: "8px"
            }}
          >
            {target.store}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            padding: "0 8px"
          }}
          variant="body2"
        >
          {target.bannerName}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            textAlign: "left",
            padding: "0 8px"
          }}
          variant="body2"
        >
          {target.location}
        </Typography>
      </TableCell>
      {selectedButton !== "unmapped" && (
        <TableCell>
          <Typography
            sx={{
              textAlign: "left",
              padding: "0 8px"
            }}
            variant="body2"
          >
            {target.propertyValue}
          </Typography>
        </TableCell>
      )}
    </TableRow>
  )
}

export default StoreMappingRow
