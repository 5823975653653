import React from "react"
import { Box, Typography } from "@mui/material"

interface Props {
  text: string
}

const NoStoresPlaceholder = ({ text }: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "16px",
        height: "100%"
      }}
    >
      <img
        src="/noStoresPlaceholder.png"
        alt="No selection"
        style={{
          height: "120px",
          width: "120px"
        }}
      />
      <Typography
        variant="body2"
        sx={{
          fontSize: "18px",
          textAlign: "center",
          color: "secondary.dark",
          fontWeight: 600
        }}
      >
        {text}
      </Typography>
    </Box>
  )
}

export default NoStoresPlaceholder
