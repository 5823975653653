import React from "react"
import { Box, Button, Typography, Tooltip } from "@mui/material"
import FileUpload from "../../icons/FileUpload"
import CloudUpload from "../../icons/CloudUpload"

export type FileUploadProps = {
  accept: string
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleDrop?: (event: React.DragEvent<HTMLElement>) => void
  isDragging: boolean
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>
  disabled: boolean
  orientation?: string
}

const CampaignAssetUploader: React.FC<FileUploadProps> = ({
  accept = ".png, .jpg, .jpeg",
  handleChange,
  handleDrop,
  isDragging,
  setIsDragging,
  disabled,
  orientation
}) => {
  return (
    <>
      <input
        onChange={handleChange}
        accept={accept}
        style={{ display: "none" }}
        id="file-upload"
        type="file"
        disabled={disabled}
        data-testid="file-upload"
      />

      <Box
        data-testid="file-uploader-input"
        component={"label"}
        htmlFor="file-upload"
        onDragOver={disabled ? undefined : (e) => e.preventDefault()}
        onDrop={disabled ? undefined : handleDrop}
        onDragEnter={() => !disabled && setIsDragging(true)}
        onDragLeave={() => !disabled && setIsDragging(false)}
        gap="10px"
        sx={{
          cursor: disabled ? "default" : "pointer",
          textAlign: "center",
          display: "flex",
          border: "none",
          backgroundImage: isDragging
            ? "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23109AB5FF' stroke-width='4' stroke-dasharray='6%2c 8.3' stroke-dashoffset='1' stroke-linecap='round'/%3e%3c/svg%3e\")"
            : "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23BFBFC9FF' stroke-width='3' stroke-dasharray='6%2c 8.3' stroke-dashoffset='1' stroke-linecap='round'/%3e%3c/svg%3e\")",
          backgroundColor: isDragging ? "secondary.contrastText" : "inherit",
          borderRadius: "8px",
          height: "400px",
          alignItems: "center",
          justifyContent: "center",
          padding: "12px",
          "&:hover": {
            backgroundColor: !disabled ? "secondary.contrastText" : "inherit",
            color: !disabled ? "text.secondary" : "inherit",
            textDecoration: "none"
          }
        }}
      >
        <Box sx={{ pointerEvents: "none" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              width: "100%",
              gap: "12px"
            }}
          >
            {isDragging ? (
              <Box
                display="flex"
                width="20px"
                height="20px"
                color="text.secondary"
              >
                <CloudUpload />
              </Box>
            ) : (
              <Box display="flex" flexDirection="column" gap="4px">
                {orientation && !disabled && (
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "18px",
                      opacity: disabled ? 0.5 : 1
                    }}
                  >
                    {`${
                      orientation === "16:9" ? "Landscape" : "Portrait"
                    } (${orientation})`}
                  </Typography>
                )}
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: disabled ? "18px" : "16px",
                    opacity: disabled ? 1 : 0.5
                  }}
                >
                  Choose a file or drag and drop it here.
                </Typography>
              </Box>
            )}

            {isDragging ? (
              <Typography variant="body2" color="text.secondary">
                Drop your files here
              </Typography>
            ) : (
              <Box sx={{ pointerEvents: disabled ? "auto" : "none" }}>
                <Tooltip
                  title={disabled ? "To upload, select zone above." : ""}
                  placement="bottom"
                >
                  <span>
                    <Button
                      variant="outlined"
                      startIcon={
                        <FileUpload sx={{ height: "18px", width: "18px" }} />
                      }
                      disabled={disabled}
                      sx={{ opacity: disabled ? 0.5 : 1 }}
                    >
                      <Typography
                        component="span"
                        variant="body2"
                        sx={{ fontWeight: 600, opacity: disabled ? 0.5 : 1 }}
                      >
                        Upload File
                      </Typography>
                    </Button>
                  </span>
                </Tooltip>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default CampaignAssetUploader
