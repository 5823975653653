import React from "react"
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import { StoreBanner } from "../../models/storetarget"
import { useFormContext } from "react-hook-form"

interface PropertyValueApiReponse {
  id: number
  name: string
  is_editable: boolean
}

interface PropertyApiResponse {
  id: number
  name: string
  type: string
  values: PropertyValueApiReponse[]
}

interface StoreFiltersProps {
  campaignBannerList?: StoreBanner[]
  campaignStateList?: string[]
  storePropertyList?: PropertyApiResponse[]
  expandedPanels: string[]
  handleBannerChange: (bannerId: number) => void
  handleStateChange: (state: string) => void
  handleValueChange: (valueId: number) => void
  handleAccordionChange: (
    panel: string
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void
}

const StoreFilters = ({
  campaignBannerList,
  campaignStateList,
  storePropertyList,
  expandedPanels,
  handleBannerChange,
  handleStateChange,
  handleValueChange,
  handleAccordionChange
}: StoreFiltersProps) => {
  const { watch } = useFormContext()

  const selectedBanners = watch("selectedBanners", [])
  const selectedStates = watch("selectedStates", [])
  const selectedPropertyValues = watch("selectedPropertyValues", [])

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {campaignBannerList && campaignBannerList.length > 1 && (
        <Box
          sx={{
            display: "flex"
          }}
        >
          <Accordion
            expanded={expandedPanels.includes("panel-banner")}
            onChange={handleAccordionChange("panel-banner")}
            sx={{
              boxShadow: "none",
              width: "100%",
              "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                transform: "rotate(0deg)"
              }
            }}
          >
            <AccordionSummary
              expandIcon={
                expandedPanels.includes("panel-banner") ? (
                  <RemoveIcon sx={{ width: "16px", height: "16px" }} />
                ) : (
                  <AddIcon sx={{ width: "16px", height: "16px" }} />
                )
              }
              sx={{
                minHeight: "40px",
                "&.Mui-expanded": {
                  minHeight: "40px",
                  margin: 0
                },
                "& .MuiAccordionSummary-content": {
                  margin: "0"
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  margin: "9px 0"
                }
              }}
            >
              <Typography variant="body2">Banner</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: 0
              }}
            >
              {campaignBannerList.map((banner) => (
                <FormControlLabel
                  key={banner.id}
                  style={{
                    backgroundColor: selectedBanners.includes(banner.id)
                      ? "#DCDFFF"
                      : "transparent",
                    paddingLeft: "5px",
                    margin: 0
                  }}
                  control={
                    <Checkbox
                      value={banner.id}
                      checked={selectedBanners.includes(banner.id)}
                      onChange={() => handleBannerChange(banner.id)}
                      sx={{
                        color: "secondary.dark",
                        padding: 1,
                        "&.Mui-checked": {
                          color: "primary.light"
                        }
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      sx={{
                        lineHeight: 1.2,
                        wordBreak: "break-word"
                      }}
                    >
                      {banner.name}
                    </Typography>
                  }
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      display: "flex",
                      alignItems: "center"
                    }
                  }}
                />
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
      {campaignStateList && campaignStateList.length > 1 && (
        <Box
          sx={{
            display: "flex",
            borderTop: "1px solid",
            borderColor: "secondary.light"
          }}
        >
          <Accordion
            expanded={expandedPanels.includes("panel-state")}
            onChange={handleAccordionChange("panel-state")}
            sx={{
              boxShadow: "none",
              width: "100%",
              "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                transform: "rotate(0deg)"
              }
            }}
          >
            <AccordionSummary
              expandIcon={
                expandedPanels.includes("panel-state") ? (
                  <RemoveIcon sx={{ width: "16px", height: "16px" }} />
                ) : (
                  <AddIcon sx={{ width: "16px", height: "16px" }} />
                )
              }
              sx={{
                minHeight: "40px",
                "&.Mui-expanded": {
                  minHeight: "40px",
                  margin: 0
                },
                "& .MuiAccordionSummary-content": {
                  margin: "0"
                },
                "& .MuiAccordionSummary-content.Mui-expanded": {
                  margin: "9px 0"
                }
              }}
            >
              <Typography variant="body2">State</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: 0
              }}
            >
              {campaignStateList.map((state) => (
                <FormControlLabel
                  key={state}
                  style={{
                    backgroundColor: selectedStates.includes(state)
                      ? "#DCDFFF"
                      : "transparent",
                    paddingLeft: "5px",
                    margin: 0
                  }}
                  control={
                    <Checkbox
                      value={state}
                      checked={selectedStates.includes(state)}
                      onChange={() => handleStateChange(state)}
                      sx={{
                        color: "secondary.dark",
                        padding: 1,
                        "&.Mui-checked": {
                          color: "primary.light"
                        }
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      sx={{
                        lineHeight: 1.2,
                        wordBreak: "break-word",
                        marginRight: "12px"
                      }}
                    >
                      {state}
                    </Typography>
                  }
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      display: "flex",
                      alignItems: "center"
                    }
                  }}
                />
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>
      )}

      {storePropertyList &&
        storePropertyList.map((property) => (
          <Box
            key={property.id}
            sx={{
              display: "flex",
              borderTop: "1px solid",
              borderColor: "secondary.light"
            }}
          >
            <Accordion
              key={property.id}
              expanded={expandedPanels.includes(`panel-${property.id}`)}
              onChange={handleAccordionChange(`panel-${property.id}`)}
              sx={{
                boxShadow: "none",
                width: "100%",
                "&:last-of-type": {
                  borderBottomLeftRadius: "8px",
                  borderBottomRightRadius: "8px"
                },
                "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                  transform: "rotate(0deg)"
                }
              }}
            >
              <AccordionSummary
                expandIcon={
                  expandedPanels.includes(`panel-${property.id}`) ? (
                    <RemoveIcon sx={{ width: "16px", height: "16px" }} />
                  ) : (
                    <AddIcon sx={{ width: "16px", height: "16px" }} />
                  )
                }
                aria-controls={`panel-${property.id}-content`}
                id={`panel-${property.id}-header`}
                sx={{
                  minHeight: "40px",
                  "&.Mui-expanded": {
                    minHeight: "40px",
                    margin: 0
                  },
                  "& .MuiAccordionSummary-content": {
                    margin: "0"
                  },
                  "& .MuiAccordionSummary-content.Mui-expanded": {
                    margin: "9px 0"
                  }
                }}
              >
                <Typography variant="body2">{property.name}</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 0
                }}
              >
                {property.values
                  .filter((value) => value.name !== "Not Applicable")
                  .map((value) => (
                    <FormControlLabel
                      key={value.id}
                      style={{
                        backgroundColor: selectedPropertyValues.includes(
                          value.id
                        )
                          ? "#DCDFFF"
                          : "transparent",
                        paddingLeft: "5px",
                        margin: 0
                      }}
                      control={
                        <Checkbox
                          value={value.id}
                          checked={selectedPropertyValues.includes(value.id)}
                          onChange={() => handleValueChange(value.id)}
                          sx={{
                            color: "secondary.dark",
                            padding: 1,
                            "&.Mui-checked": {
                              color: "primary.light"
                            }
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          sx={{
                            lineHeight: 1.2,
                            wordBreak: "break-word",
                            marginRight: "12px"
                          }}
                        >
                          {value.name}
                        </Typography>
                      }
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          display: "flex",
                          alignItems: "center"
                        }
                      }}
                    />
                  ))}
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
    </Box>
  )
}

export default StoreFilters
