/* eslint-disable indent */
import React, { useEffect, useRef, useState } from "react"

import {
  useFormContext,
  Controller,
  useFieldArray,
  useWatch
} from "react-hook-form"
import {
  Box,
  TextField,
  IconButton,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  RadioGroup,
  Button,
  FormControlLabel,
  Radio
} from "@mui/material"
import TrashOutline from "../../icons/TrashOutline"
import CircleAdd from "../../icons/CircleAdd"

interface PropertyItem {
  value: string
  valueId?: string
  isEditable?: boolean
}
interface PropertyForm {
  propertyName: string
  propertyItems: PropertyItem[]
  propertyType: string
  notApplicable?: PropertyItem
}

interface PropertyValue {
  id: number
  name: string
  is_editable: boolean
}

interface PropertyApiResponse {
  id: number
  name: string
  type: string
  values: PropertyValue[]
}

interface SetupStepProps {
  setDefineAttributeComplete: React.Dispatch<React.SetStateAction<boolean>>
  isEditMode: boolean
  property?: PropertyApiResponse
}

const SetupStep = ({
  setDefineAttributeComplete,
  isEditMode,
  property
}: SetupStepProps) => {
  const {
    control,
    register,
    setValue,
    clearErrors,
    formState: { errors }
  } = useFormContext<PropertyForm>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: "propertyItems"
  })

  const previousPropertyItemsRef = useRef<PropertyItem[]>([])
  const [selectedPropertyItem, setSelectedPropertyItem] = useState(0)

  const propertyName = useWatch({
    control,
    name: "propertyName"
  })

  const propertyType = useWatch({
    control,
    name: "propertyType",
    defaultValue: "multi-select"
  })

  const propertyItems = useWatch({
    control,
    name: "propertyItems"
  })

  const notApplicable = useWatch({
    control,
    name: "notApplicable",
    defaultValue: {
      valueId: "notApplicable",
      value: "Not Applicable",
      isEditable: false
    }
  })

  useEffect(() => {
    // propertyItems is now tracked using useWatch
    if (!propertyItems || propertyItems.length === 0) {
      if (isEditMode && property) {
        setValue("propertyName", property.name)
        setValue("propertyType", property.type)

        // Set propertyItems from the backend response
        const items = property.values
          .filter(
            (value) => !(value.name === "Not Applicable" && !value.is_editable)
          )
          .map((value) => ({
            valueId: value.id.toString(),
            value: value.name,
            isEditable: value.is_editable
          }))

        setValue("propertyItems", items)

        // Handle "Not Applicable" value for multi-select types
        if (property.type === "multi-select") {
          const backendNotApplicable = property.values.find(
            (value) => value.name === "Not Applicable"
          )

          if (backendNotApplicable) {
            setValue("notApplicable", {
              valueId: backendNotApplicable.id.toString(),
              value: backendNotApplicable.name,
              isEditable: backendNotApplicable.is_editable
            })
          }
        }
      } else if (!isEditMode) {
        setValue("propertyItems", [{ value: "" }])
      }
    }
  }, [isEditMode, property])

  useEffect(() => {
    // Check that ALL property items have non-empty values
    const hasValidPropertyItems = propertyItems?.every(
      (item) => item.value.trim() !== ""
    )

    // Check that ALL values are unique
    const hasUniqueValues = propertyItems?.every(
      (item, index) =>
        propertyItems.findIndex(
          (otherItem) =>
            otherItem.value.trim().toLowerCase() ===
            item.value.trim().toLowerCase()
        ) === index
    )

    const isFieldsFilled = Boolean(
      propertyName &&
        propertyType &&
        propertyItems &&
        hasValidPropertyItems &&
        hasUniqueValues
    )

    setDefineAttributeComplete(isFieldsFilled)
  }, [propertyName, propertyType, propertyItems])

  const handlePropertyTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    if (newValue !== null) {
      setValue("propertyType", newValue)

      if (newValue === "boolean") {
        previousPropertyItemsRef.current = propertyItems
        setValue("propertyItems", [
          { value: "Yes", valueId: "yes" },
          { value: "No", valueId: "no" }
        ])
        clearErrors("propertyItems")
      } else if (newValue === "multi-select") {
        setValue(
          "propertyItems",
          previousPropertyItemsRef.current.length > 0
            ? previousPropertyItemsRef.current
            : [{ value: "" }]
        )
        clearErrors("propertyItems")
      }
    }
  }

  useEffect(() => {
    if (propertyItems && propertyItems.length > 0) {
      if (propertyType === "multi-select") {
        setSelectedPropertyItem(propertyItems.length - 1)
      }
    }
  }, [propertyItems])

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const index = parseInt(event.target.value, 10)
    setSelectedPropertyItem(index)
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        minHeight: "660px"
      }}
    >
      <Box
        sx={{
          width: "50%",
          padding: "40px",
          display: "flex",
          flexDirection: "column",
          gap: "40px"
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          {isEditMode ? (
            <Typography
              variant="body2"
              sx={{ fontSize: "20px", lineHeight: "24px" }}
            >
              Manage Store Attribute
            </Typography>
          ) : (
            <>
              <Typography
                variant="body2"
                sx={{ fontSize: "20px", lineHeight: "24px" }}
              >
                Define a New Store Attribute
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: 400, fontSize: "16px" }}
              >
                A store attribute is a characteristic or feature that helps
                describe your stores. Attributes make it easy to filter and
                select stores based on shared qualities when scheduling a
                promotion.
              </Typography>
            </>
          )}
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Typography variant="body2" sx={{ fontSize: "16px" }}>
            Name your attribute
          </Typography>
          <Typography variant="body2" component="label">
            Name
            <Box
              component="span"
              sx={{ color: "error.main", marginLeft: "2px" }}
            >
              *
            </Box>
          </Typography>

          <TextField
            {...register("propertyName", {
              required: "Property Name is required",
              validate: (value) =>
                value.trim() !== "" || "Property Name is required"
            })}
            fullWidth
            placeholder="Example: Region or Pharmacy"
            error={!!errors.propertyName}
            helperText={errors.propertyName?.message}
          />
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Typography
            variant="body2"
            component="label"
            sx={{ fontSize: "16px" }}
          >
            Define the groups for this attribute
          </Typography>
          <Controller
            name="propertyType"
            control={control}
            defaultValue="multi-select"
            render={({ field }) => (
              <ToggleButtonGroup
                value={field.value}
                exclusive
                onChange={handlePropertyTypeChange}
                disabled={isEditMode}
                sx={{
                  height: "28px",
                  padding: "2px",
                  borderColor: "primary.contrastText",
                  width: "fit-content",
                  backgroundColor: "secondary.contrastText",
                  borderRadius: "80px",
                  "& .MuiToggleButton-root.Mui-selected": {
                    backgroundColor: "secondary.main",
                    borderRadius: "80px",
                    color: "fileInput.dark",
                    height: "24px",
                    "&:hover": {
                      backgroundColor: "secondary.main"
                    }
                  },
                  "& .MuiToggleButton-root": {
                    backgroundColor: "secondary.contrastText",
                    color: "text.primary",
                    border: "none",
                    height: "24px",
                    transition: "all 0.2s",
                    "&:first-of-type": {
                      borderTopLeftRadius: "80px",
                      borderBottomLeftRadius: "80px",
                      marginRight: "2px",
                      "&.Mui-disabled": {
                        color: "disabled.main",
                        border: "none"
                      }
                    },
                    "&:last-of-type": {
                      borderTopRightRadius: "80px",
                      borderBottomRightRadius: "80px",
                      marginLeft: "2px",
                      "&.Mui-disabled": {
                        color: "disabled.main"
                      }
                    }
                  }
                }}
              >
                <ToggleButton
                  value="multi-select"
                  sx={{
                    width: "125px"
                  }}
                >
                  Multiple
                </ToggleButton>
                <ToggleButton value="boolean" sx={{ width: "125px" }}>
                  Yes/No
                </ToggleButton>
              </ToggleButtonGroup>
            )}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          {propertyType === "multi-select" && (
            <>
              <Typography variant="body2" component="label">
                Name your groups
                <Box
                  component="span"
                  sx={{ color: "error.main", marginLeft: "2px" }}
                >
                  *
                </Box>
              </Typography>
              {fields &&
                fields.map((field, index) => (
                  <Box
                    key={field.id}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Controller
                      name={`propertyItems.${index}.value`}
                      control={control}
                      defaultValue={field.value || ""}
                      rules={{
                        required: "This field is required",
                        validate: (value) =>
                          value.trim() !== "" || "This field is required"
                      }}
                      render={({ field: controllerField }) => (
                        <TextField
                          {...controllerField}
                          placeholder="Example: South, NorthWest, East. etc"
                          fullWidth
                          onChange={(e) => {
                            setValue(
                              `propertyItems.${index}.value`,
                              e.target.value
                            )
                            if (!field.valueId) {
                              setValue(
                                `propertyItems.${index}.valueId`,
                                field.id
                              )
                            }
                          }}
                          error={!!errors.propertyItems?.[index]?.value}
                          helperText={
                            errors.propertyItems?.[index]?.value?.message || ""
                          }
                          disabled={field?.isEditable === false}
                        />
                      )}
                    />
                    {field?.isEditable !== false && index > 0 && (
                      <IconButton onClick={() => remove(index)}>
                        <TrashOutline sx={{ height: "20px" }} />
                      </IconButton>
                    )}
                  </Box>
                ))}

              <Button
                variant="outlined"
                onClick={() => append({ value: "" })}
                sx={{ width: "fit-content" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "4px"
                  }}
                >
                  <CircleAdd sx={{ height: "18px" }} />
                  <Typography variant="body2" sx={{ fontWeight: 600 }}>
                    Add More
                  </Typography>
                </Box>
              </Button>
            </>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          width: "50%",
          padding: "40px",
          boxSizing: "border-box",
          background:
            "linear-gradient(180deg, rgba(237, 239, 255, 0.6) 0%, rgba(168, 46, 180, 0.36) 141.45%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "40px"
        }}
      >
        <Box sx={{ width: "55%", textAlign: "center" }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 500,
              color: "text.primary"
            }}
          >
            In the next step, you&apos;ll assign each store to an available
            group.
          </Typography>
        </Box>

        <Box
          sx={{
            width: "335px",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "secondary.main",
            borderRadius: "8px",
            border: "1px solid",
            borderColor: "secondary.light",
            height: "fit-content"
          }}
        >
          <Box>
            <Typography
              variant="body2"
              sx={{
                fontWeight: "600",
                padding: "9px 12px",
                borderBottom: "1px solid",
                borderColor: "secondary.light"
              }}
            >
              {propertyName && propertyName.trim() ? propertyName : "Region"}
            </Typography>

            <RadioGroup
              key={propertyType}
              value={
                isNaN(selectedPropertyItem)
                  ? notApplicable?.value
                  : selectedPropertyItem
              }
              onChange={handleOptionChange}
              sx={{
                "& .MuiFormControlLabel-root:has(.Mui-checked)": {
                  backgroundColor: "selectedRow.main",
                  width: "100%"
                },
                "& .MuiFormControlLabel-root": {
                  margin: 0,
                  height: "40px",
                  borderBottom: "1px solid",
                  borderColor: "secondary.light"
                },
                "& .MuiRadio-root": {
                  color: "secondary.dark",
                  padding: "0 8px 0 12px" // Add padding to radio button
                },
                "& .Mui-checked": {
                  color: "primary.dark"
                },
                "& .MuiTypography-root": {
                  fontSize: "14px"
                }
              }}
            >
              {propertyType === "boolean" ? (
                propertyItems &&
                propertyItems.map((item, index) => (
                  <FormControlLabel
                    key={index}
                    value={index}
                    control={<Radio size="small" />}
                    label={item.value}
                    sx={{ height: "32px" }}
                  />
                ))
              ) : (
                <>
                  {propertyItems &&
                    propertyItems.map((item, index) => (
                      <FormControlLabel
                        key={index}
                        value={index}
                        control={
                          <Radio
                            size="small"
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: "18px"
                              }
                            }}
                          />
                        }
                        label={
                          item.value && item.value !== "" ? item.value : "South"
                        }
                        sx={{ height: "32px" }}
                      />
                    ))}

                  <FormControlLabel
                    value={notApplicable?.value}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: "18px"
                          }
                        }}
                      />
                    }
                    label={notApplicable?.value}
                    sx={{ height: "32px" }}
                  />
                </>
              )}
            </RadioGroup>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default SetupStep
