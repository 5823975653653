import React from "react"
import { Status } from "../../models/campaign"
import { Chip, ChipOwnProps } from "@mui/material"

interface Props {
  status: Status
  sx?: ChipOwnProps["sx"]
}

const StatusBadge = (props: Props) => {
  const statusBackgroundColor = () => {
    const statusLowercase = props.status.toLowerCase()
    switch (statusLowercase) {
      case "scheduled":
        return "#FFE8B0"
      case "active":
        return "#C8ECE7"
      case "ended":
        return "#DDDDE7"
      case "draft":
        return "#FFE8B0"
    }
  }

  const statusTextColor = () => {
    const statusLowercase = props.status.toLowerCase()
    switch (statusLowercase) {
      case "scheduled":
        return "#363AA0"
      case "Active":
        return "#03423A"
      case "ended":
        return "#28282F"
      case "draft":
        return "#5E3F08"
    }
  }

  return (
    <Chip
      label={props.status}
      sx={{
        background: statusBackgroundColor(),
        color: statusTextColor(),
        fontWeight: "600",
        fontSize: "12px",
        height: "20px",
        ...props.sx
      }}
    />
  )
}

export default StatusBadge
