import React from "react"
import { Box, Checkbox, Typography, TableRow, TableCell } from "@mui/material"
import { StoreTargetMapping } from "src/models/storetarget"

interface HeaderRowProps {
  filteredTargets: StoreTargetMapping[]
  columns: string[]
  isAllTargetsSelected: boolean
  onSelectAllTargets: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
}

const StoreMappingHeaderRow = ({
  columns,
  isAllTargetsSelected,
  onSelectAllTargets,
  filteredTargets
}: HeaderRowProps) => {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell key={column}>
          <Box sx={{ display: column === "Store" ? "flex" : "table-cell" }}>
            {column === "Store" && (
              <Checkbox
                checked={isAllTargetsSelected}
                onChange={onSelectAllTargets}
                disabled={filteredTargets.length === 0}
                size="small"
                sx={{
                  color: "secondary.dark",
                  padding: 0,
                  "&.Mui-checked": {
                    color: "primary.light"
                  }
                }}
              />
            )}
            <Typography
              variant="body2"
              sx={{ paddingLeft: "8px", color: "fileInput.dark" }}
            >
              {column}
            </Typography>
          </Box>
        </TableCell>
      ))}
    </TableRow>
  )
}

export default StoreMappingHeaderRow
