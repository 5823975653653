import React from "react"
import {
  Box,
  Typography,
  List,
  Modal,
  Button,
  ListItem,
  Tooltip
} from "@mui/material"
import InfoOutlineIcon from "../../icons/InfoOutlineIcon"

type AssetModalProps = {
  open?: boolean
  onClose?: () => void
  orientation?: "16:9" | "9:16"
  durationList?: (number | undefined)[]
  labelText?: string
  isTooltip?: boolean
}

const AssetGuidelinesModal = ({
  open = false,
  onClose,
  orientation,
  durationList,
  isTooltip = false,
  labelText = "Asset Guidelines"
}: AssetModalProps) => {
  const content = (
    <>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 600,
          color: "secondary.main"
        }}
      >
        {orientation === "16:9"
          ? "Landscape Asset Guidelines"
          : "Portrait Asset Guidelines"}
      </Typography>
      <List
        component="div"
        aria-labelledby="landscape-img-requirement"
        sx={{
          listStyleType: "disc",
          listStylePosition: "inside",

          "& .MuiListItem-root": {
            fontSize: "14px",
            color: "secondary.main",
            paddingLeft: "8px",
            paddingRight: "0px",
            paddingBottom: 0
          }
        }}
      >
        <ListItem
          sx={{
            display: "list-item",
            color: "fileInput.light",

            paddingBottom: 0
          }}
        >
          Supported file types: png, jpg, mp4
        </ListItem>
        <ListItem
          sx={{
            display: "list-item"
          }}
        >
          Video duration: {durationList?.[0] ?? 6}s to{" "}
          {durationList?.[durationList.length - 1] ?? 30}s
        </ListItem>
        <ListItem
          sx={{
            display: "list-item"
          }}
        >
          Minimum resolution:{" "}
          {orientation === "16:9" ? "1280x720 px" : "720x1280 px"}
        </ListItem>

        <ListItem
          sx={{
            display: "list-item"
          }}
        >
          Max file size: 50mb
        </ListItem>
      </List>
    </>
  )

  if (isTooltip) {
    return (
      <Tooltip
        title={content}
        data-testid="asset-guidelines-tooltip"
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: "asset.light",
              position: "absolute",
              borderRadius: "8px",
              padding: "24px",
              width: "320px",
              overflowY: "auto"
            }
          }
        }}
        placement="bottom-start"
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [40, -5]
                }
              }
            ]
          }
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontWeight: 600,
            color: "secondary.dark",
            display: "flex",
            alignItems: "center",
            cursor: "pointer"
          }}
        >
          <InfoOutlineIcon
            sx={{
              height: "16px",
              width: "16px"
            }}
          />
          {labelText}
        </Typography>
      </Tooltip>
    )
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "space-around"
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          bgcolor: "asset.light",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "8px",
          padding: "24px",
          width: "320px",
          overflowY: "auto"
        }}
      >
        {content}
        <Box
          display="flex"
          justifyContent="flex-end"
          sx={{
            marginTop: "30px"
          }}
        >
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{
              height: "36px",
              width: "84px",
              color: "secondary.main"
            }}
          >
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default AssetGuidelinesModal
