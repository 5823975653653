import React from "react"
import { useNavigate } from "react-router-dom"
import { gql, useMutation } from "urql"
import { getInitials, getUserName } from "../../utils"
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Link
} from "@mui/material"
import { useFlags } from "launchdarkly-react-client-sdk"

const loginQuery = gql`
  mutation Logout {
    logout
  }
`

const NavBar = () => {
  const currentUserName = getUserName()
  const companyName = localStorage.getItem("companyName") || "Retailer"
  const { storeProperties } = useFlags()

  const navigate = useNavigate()
  const [, logout] = useMutation(loginQuery)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const userInitial = getInitials(currentUserName)

  const handleLogout = async () => {
    try {
      const result = await logout()
      if (result.data?.logout) {
        localStorage.clear()
        navigate("/")
        location.reload()
      }
    } catch (error) {
      console.error("Logout Error:", error)
    }
  }

  return (
    <AppBar
      position="fixed"
      color="inherit"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        padding: "0px 10%",
        boxShadow: "none",
        borderBottom: (theme) => `1px solid ${theme.palette.secondary.light}`
      }}
    >
      <Toolbar
        sx={{
          padding: 0,
          "&.MuiToolbar-root": {
            paddingLeft: 0,
            paddingRight: 0
          }
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{
            height: "28px",
            gap: "24px"
          }}
        >
          <img
            src="/grocery-tv-banner-logo.svg"
            alt="Grocery TV"
            style={{ height: "17px" }}
          />
          <Box
            display="flex"
            sx={{
              justifyContent: "center",
              alignItems: "center",
              borderLeft: "1px solid",
              borderColor: "primary.contrastText",
              paddingLeft: "24px"
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: 600, color: "secondary.dark" }}
            >
              {companyName}
            </Typography>
          </Box>
        </Box>
        <Box flexGrow={1} display="flex" justifyContent="space-around">
          <Typography></Typography>
        </Box>
        <Box display="flex" alignItems="center" gap="24px">
          <Typography
            variant="body2"
            sx={{ fontWeight: 600, cursor: "pointer" }}
            onClick={() => navigate("/")}
          >
            Promotions
          </Typography>
          <IconButton onClick={handleClick}>
            <Avatar
              sx={{
                height: "28px",
                width: "28px",
                fontSize: "12px",
                fontWeight: 600,
                bgcolor: "avatar.main",
                cursor: "pointer"
              }}
            >
              {userInitial}
            </Avatar>
          </IconButton>
          <Menu
            id="nav-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: -2,
              horizontal: "right"
            }}
            MenuListProps={{
              disablePadding: true
            }}
            sx={{
              transform: "translateX(-3px)",
              "& .MuiPaper-root": {
                paddingY: "12px",
                borderRadius: "6px",
                border: "1px solid",
                borderColor: "primary.contrastText",
                width: "203px",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
              },
              "& .MuiMenuItem-root": {
                padding: "8px 16px",
                fontSize: "14px"
              }
            }}
          >
            <MenuItem
              onClick={() => {
                navigate("/account")
              }}
            >
              Account
            </MenuItem>
            {storeProperties && (
              <MenuItem
                onClick={() => {
                  navigate("/storeattributes")
                }}
              >
                Store Attributes
              </MenuItem>
            )}
            <MenuItem onClick={handleLogout}>Log out</MenuItem>
            <MenuItem onClick={handleClose}>
              <Link href="mailto:support@grocerytv.com" underline="none">
                <Box display="flex" flexDirection="column">
                  <Typography
                    component="span"
                    sx={{ fontSize: "12px", color: "secondary.dark" }}
                  >
                    Need help?
                  </Typography>
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{
                      textDecoration: "none",
                      "&:hover": {
                        textDecoration: "underline",
                        color: "hint.main"
                      }
                    }}
                  >
                    support@grocerytv.com
                  </Typography>
                </Box>
              </Link>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default NavBar
