/* eslint-disable indent */
import React, { useState } from "react"
import {
  TableRow,
  TableCell,
  Checkbox,
  Box,
  Typography,
  Table,
  TableBody,
  Tooltip
} from "@mui/material"
import { capitalize } from "lodash"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useFormContext } from "react-hook-form"
import { Placement, ZonePlacement } from "../../../models/zoneplacement"
import { CampaignFormModel } from "../../../models/campaign"
import { updateZonePlacementSelection } from "../../../utils"
import { useFlags } from "launchdarkly-react-client-sdk"
import InfoIcon from "../../icons/InfoIcon"
import HoverIcon from "../../icons/HoverIcon"
import InfoOutlineIcon from "../../icons/InfoOutlineIcon"

interface Props {
  zonePlacement: ZonePlacement
  onSelectZone: (zone: ZonePlacement, isLastPlacement?: boolean) => void
  isSelected: boolean
  disabled?: boolean
  setOpenResetConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>
  setTempPlacementId: React.Dispatch<React.SetStateAction<number | undefined>>
  isCampaignEnded?: boolean
  showPlacements?: boolean
  isReadOnly?: boolean
}

const ZonePlacementDisplay = ({
  zonePlacement,
  onSelectZone,
  isSelected,
  disabled,
  setOpenResetConfirmationModal,
  setTempPlacementId,
  isCampaignEnded,
  showPlacements,
  isReadOnly
}: Props) => {
  const { watch, setValue } = useFormContext<CampaignFormModel>()
  const selectedZonePlacements = watch("selectedZonePlacements") || []
  const selectedZones = watch("selectedZones") || []
  const activeStores = watch("activeStores")
  const file = watch("file")
  const { placementTargeting } = useFlags()
  const [isExpanded, setIsExpanded] = useState(false)

  const placementCount = zonePlacement.placements?.length || 0
  const selectedPlacementIds = React.useMemo(() => {
    const selection = selectedZonePlacements.find(
      (selection) => selection.zoneId === zonePlacement.id
    )
    return selection?.placementIds || []
  }, [selectedZonePlacements, zonePlacement?.id])

  const selectedCount = selectedPlacementIds.length
  const indeterminate = selectedCount > 0 && !isSelected

  const handleCheckboxChange = (event: React.MouseEvent) => {
    event.stopPropagation()
    if (!disabled && !isCampaignEnded && !isReadOnly) {
      onSelectZone(zonePlacement)
    }
  }

  const handlePlacementToggle = (placementId: number) => {
    const newSelectedIds = selectedPlacementIds.includes(placementId)
      ? selectedPlacementIds.filter((id) => id !== placementId)
      : [...selectedPlacementIds, placementId]

    const hasActiveStores = (activeStores?.length ?? 0) > 0
    const isLastPlacement = newSelectedIds.length === 0
    const isDeselecting = selectedPlacementIds.includes(placementId)

    if (isLastPlacement && (hasActiveStores || file?.asset)) {
      onSelectZone(zonePlacement, true)
      return
    }

    if (selectedPlacementIds.includes(placementId) && hasActiveStores) {
      setTempPlacementId(placementId)
      setOpenResetConfirmationModal(true)
      return
    }

    const newSelectedZonePlacements = updateZonePlacementSelection(
      selectedZonePlacements,
      zonePlacement.id,
      newSelectedIds
    ).filter((selection) => selection.placementIds.length > 0)

    setValue("selectedZonePlacements", newSelectedZonePlacements)

    if (isDeselecting) {
      const newSelectedZones = selectedZones.filter(
        (zone) => zone.id !== zonePlacement.id
      )
      setValue("selectedZones", newSelectedZones)
    } else if (newSelectedIds.length === placementCount) {
      setValue("selectedZones", [...selectedZones, zonePlacement])
    }
  }

  const getSelectedPlacements = (placements: Placement[]) => {
    return placements.filter((placement) =>
      selectedPlacementIds.includes(placement.id)
    )
  }
  const displayPlacements = isReadOnly
    ? getSelectedPlacements(zonePlacement.placements || [])
    : zonePlacement.placements

  return (
    <>
      <TableRow
        sx={{
          height: "36px",
          backgroundColor:
            isSelected && !isReadOnly ? "selectedRow.main" : "inherit"
        }}
      >
        <TableCell
          sx={{
            padding: 0,
            opacity: disabled || isCampaignEnded ? 0.5 : 1,
            paddingLeft: isReadOnly ? "16px" : "0"
          }}
          onClick={handleCheckboxChange}
        >
          {!isReadOnly && (
            <Checkbox
              checked={isSelected}
              indeterminate={indeterminate}
              sx={{
                color: "secondary.dark",
                height: "36px",
                width: "36px",
                borderRadius: "50%",
                "&.Mui-checked": {
                  color: "primary.light"
                },
                "&.MuiCheckbox-indeterminate": {
                  color: "primary.light"
                }
              }}
              disabled={disabled || isCampaignEnded}
            />
          )}
          {zonePlacement.name}
        </TableCell>
        <TableCell
          sx={{
            color: disabled || isCampaignEnded ? "disabled.main" : "inherit"
          }}
          onClick={handleCheckboxChange}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "8px"
            }}
          >
            {capitalize(zonePlacement.orientation)}
            {disabled && !isCampaignEnded && (
              <Tooltip
                title="You can only select zones with the same orientation."
                placement="right"
                TransitionProps={{ style: { marginTop: "4px" } }}
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: "180px",
                      borderRadius: "4px",
                      paddingLeft: "12px",
                      lineHeight: "15px"
                    }
                  }
                }}
              >
                <span>
                  <HoverIcon
                    hover={
                      <InfoIcon
                        sx={{
                          height: "16px",
                          width: "16px",
                          color: "text.primary"
                        }}
                      />
                    }
                    normal={
                      <InfoOutlineIcon
                        sx={{
                          height: "16px",
                          width: "16px"
                        }}
                      />
                    }
                    sx={{
                      height: "16px",
                      width: "16px",
                      cursor: "pointer"
                    }}
                  />
                </span>
              </Tooltip>
            )}
          </Box>
        </TableCell>
        <TableCell
          align="right"
          sx={{
            paddingX: "16px"
          }}
        >
          {(((placementTargeting || showPlacements) &&
            (isSelected || indeterminate) &&
            placementCount !== 0) ||
            isExpanded) && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                color: "primary.light"
              }}
            >
              <Typography
                sx={{
                  color: "primary.light"
                }}
                onClick={handleCheckboxChange}
              >{`${selectedCount}/${placementCount}`}</Typography>
              <Box
                onClick={(e) => {
                  e.stopPropagation()
                  if (!isCampaignEnded) {
                    setIsExpanded(!isExpanded)
                  }
                }}
                sx={{
                  ml: 1,
                  cursor: !isCampaignEnded ? "pointer" : "default"
                }}
              >
                <ExpandMoreIcon
                  sx={{
                    transform: isExpanded ? "rotate(180deg)" : "none",
                    transition: "transform 0.2s"
                  }}
                />
              </Box>
            </Box>
          )}
        </TableCell>
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell colSpan={3} sx={{ p: 0 }}>
            <Table>
              <TableBody>
                {displayPlacements?.map((placement) => (
                  <TableRow key={placement.id}>
                    <TableCell
                      sx={{ padding: 0, height: "36px", border: "none" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "28px",
                          backgroundColor:
                            selectedPlacementIds.includes(placement.id) &&
                            !isReadOnly
                              ? "selectedRow.main"
                              : "inherit"
                        }}
                      >
                        {!isReadOnly && (
                          <Checkbox
                            checked={selectedPlacementIds.includes(
                              placement.id
                            )}
                            onChange={() => handlePlacementToggle(placement.id)}
                            sx={{
                              height: "36px",
                              width: "36px",
                              color: "secondary.dark",
                              "&.Mui-checked": {
                                color: "primary.light"
                              }
                            }}
                          />
                        )}
                        <Typography variant="body2">
                          {placement.name}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default ZonePlacementDisplay
